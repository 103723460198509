/* Common css utilities v2.0.0*/

/* Margins and Paddings*/

.mt-05 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-48 {
  margin-top: 48px;
}

.ml-30 {
  margin-left: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}

.pt-08 {
  padding-top: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-24 {
  padding-top: 24px;
}

.pb-08 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-24 {
  padding-bottom: 24px;
}

.pl-08 {
  padding-left: 8px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-24 {
  padding-left: 24px;
}

.pr-08 {
  padding-right: 8px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-24 {
  padding-right: 24px;
}

/* Vertical Align Center */
.vertical-container {
  height: 100%;
}
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


/* Misc*/
.text-capitalize {
  text-transform: capitalize;
}
.center-label {
  width: 100%;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.button-right {
  text-align: right;
}
.white-button {
  border: 2px solid #FFF;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
}
.status-text {
font-size: 16px;
color: #FFF;
}
.file-text {
  padding-left: 16px;
  font-size: 12px;
}

/* For Ion Cards*/
.border-radius-10 {
  border-radius: 10px;
}

/*To seperate sections*/
.border-top-sec {
  border-top: 1px solid var(--ion-color-medium);
}
.border-bottom-sec {
  border-bottom: 1px solid var(--ion-color-medium);
}

/* Dark Theme fix for calender api*/
.custom-calendar .Calendar__yearSelectorText, .custom-calendar .Calendar__monthSelectorItemText, .custom-calendar .Calendar__monthText, .custom-calendar .Calendar__yearText {
  color: #111111;
}

/* Ion Loader */
.first-loading {
  font-size: 14px;
  font-weight: 600;
}

/* Auth Helpers */
.auth-label {
  color: var(--ion-color-primary) !important;
  font-weight: 600;
}
.auth-input {
  color: var(--ion-color-dark) !important;
}
.auth-title {
  color: var(--ion-color-secondary) !important;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
}
.auth-link {
  color: var(--ion-color-secondary) !important;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-decoration: none;
}

/* Ion Menu*/
.menu-title {
  color: var(--ion-color-secondary);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.menu-title-1 {
  font-size: 18px !important;
  font-weight: 600;
}
.menu-note-1 {
  font-size: 16px;
}

/* Home Admissions*/
.insti-name {
  font-size: 16px;
  font-weight: 600;
}

.insti-address {
  font-size: 12px;
  color: var(--ion-color-medium) !important;
  padding-left: 16px;
}

.home-button {
  background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.6), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.6));
  border: 2px solid #FFF;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
}

.home-footer {
  background: #222428;
}

/* School Profile */
.class-card {
  border-left: 6px solid var(--ion-color-secondary);
  border-radius: 10px;
  padding: 8px;
}

.class-name {
  font-weight: 600;
  font-size: 16px;
}

.class-info {
  font-size: 12px;
}

.class-info-2 {
  font-size: 12px;
  font-weight: 600;
  color: var(--ion-color-secondary);
}

.class-info-3 {
  font-size: 14px;
  font-weight: 600;
}

.info-subtitle {
  font-size: 16px;
  font-weight: 600;
}

.fac-icon {
  font-size: 18px;
}
.fac-label {
  font-size: 14px !important;
}

.stat-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-dark);
}

/* Admissions User */
.note-card {
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  border: 1px solid var(--ion-color-primary);
  padding: 4px;
}
.note-card-1 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  border: 1px solid var(--ion-color-secondary);
  padding: 4px;
}
.note-icon {
  font-size: 24px;
}
.note-text p {
  font-size: 12px;

  color: var(--ion-color-dark);
  line-height: 18px;
}

.action-card-one {
  border-radius: 10px;
}

.action-title-one h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-secondary);
  line-height: 36px;
}

.child-card {
  border-radius: 10px;
}

.child-card-selected {
  border-radius: 10px;
  border: 1px solid var(--ion-color-secondary);
}

.child-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-secondary);
  line-height: 36px;
  padding-left: 8px;
}

.child-label p {
  font-size: 16px;
}

/* Dashboard */

.dash-card-1 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(255, 125, 69, 0.3), rgba(255, 125, 69, 0.9) 40%, rgba(255, 125, 69, 0.3));
  height: 150px;
}

.dash-card-2 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(78, 40, 122, 0.3), rgba(78, 40, 122, 0.9) 40%, rgba(78, 40, 122, 0.3));
  height: 150px;
}

.dash-card-3 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-danger-rgb), 0.3), rgba(var(--ion-color-danger-rgb), 0.9) 40%, rgba(var(--ion-color-danger-rgb), 0.3));
  height: 150px;
}

.dash-card-4 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(0, 111, 96, 0.3), rgba(0, 111, 96, 0.9) 40%, rgba(0, 111, 96, 0.3));
  height: 150px;
}

.dash-card-5 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.3), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.3));
  height: 150px;
}

.dash-card-6 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-primary-rgb), 0.3), rgba(var(--ion-color-primary-rgb), 0.9) 40%, rgba(var(--ion-color-primary-rgb), 0.3));
  height: 150px;
}

.dash-card-7 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-success-rgb), 0.3), rgba(var(--ion-color-success-rgb), 0.9) 40%, rgba(var(--ion-color-success-rgb), 0.3));
  height: 150px;
}

.dash-title {
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  font-family: 'Segoe UI', sans-serif;
}

/*Common Form*/

.checkout-amt {
  font-size: 16px;
  font-weight: 600;
  padding-top: 4px;
  margin: 0;
}

.checkout-label {
  font-size: 12px;
  margin: 0;
  color: var(--ion-color-medium);
}

/*Router Tabs*/
.tab-button {
  --color-selected: var(--ion-color-secondary);
  --color: var(--ion-color-dark);
}

.tab-bar {
  --background: rgba(var(--ion-color-secondary-rgb), 0.1);
  border-top: 1px solid rgba(var(--ion-color-medium-rgb), 0.5);
}

.swap-sheet {
  --background: var(--ion-color-light);
  --color: var(--ion-color-secondary);
  text-transform: capitalize;
}

/* STUDENT Stats*/

.overview-icon {
  color:#FFF;
  font-size: 24px;
}

.stat-card-1 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.6), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.6));
}
.stat-card-2 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-danger-rgb), 0.6), rgba(var(--ion-color-danger-rgb), 0.9) 40%, rgba(var(--ion-color-danger-rgb), 0.6));
}
.stat-card-3 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-primary-rgb), 0.6), rgba(var(--ion-color-primary-rgb), 0.9) 40%, rgba(var(--ion-color-primary-rgb), 0.6));
}
.stat-card-4 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(0, 111, 96, 0.6), rgba(0, 111, 96, 0.9) 40%, rgba(0, 111, 96, 0.6));
}

.stat-card-5 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(255, 125, 69, 0.6), rgba(255, 125, 69, 0.9) 40%, rgba(255, 125, 69, 0.6));
}

.stat-subhead {
  color:#FFF;
}
.stat-head {
  color:#FFF;
  font-size: 16px;
  font-weight: 600;
}
.stat-icon {
  color:#FFF;
  font-size: 20px;
  margin-top: 10px;
}
.stat-subhead-1 {
  color:#000;
}
.stat-head-1 {
  color:#000;
  font-size: 16px;
  font-weight: 600;
}
.stat-icon-1 {
  color:#000;
  font-size: 20px;
  margin-top: 10px;
}

.stat-button {
  border: 2px solid #fefefe;
  color: #fefefe;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
}

/* IonLabel Headings */
.title-heading {
  font-size: 18px;
  font-weight: 600;
}


/*admin buttons title*/
.action-title {
  color: var(--ion-color-dark);
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px;
}

/*admin lists*/
.list-title h3 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: var(--ion-color-secondary);

}

.red-title h3 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: var(--ion-color-danger);

}


.list-label-2 {
  font-size: 18px;
  font-weight: 600;
}

/* isActive Segments */
.isactive-segment {
  --background: rgba(var(--ion-color-secondary-rgb), 0.4);
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .isactive-segment {
    --background: rgba(var(--ion-color-secondary-rgb));
    color: #FFF;
  }
}

.ios .active-group .list-title {
  padding: 0; 
  text-align: left;
}

/* Ion Elements with Background Color */
.home-shade {
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.grey-shade {
    --background: var(--ion-color-light);
    color: #000;
}
.primary-shade {
    --background: var(--ion-color-primary);
}
.secondary-shade {
  --background: var(--ion-color-secondary);
}
.tertiary-shade {
    --background: var(--ion-color-tertiary);
}
.quaternary-shade {
  --background: var(--ion-color-quaternary);
}
.white-shade {
    background: #fff;
    color: #111;
}
.black-shade {
    background: #000;
    color: #FFF;
}

.item-white-shade {
  --background: #fff;
  color: #111;
}

/* Ion Footer */
.footer-shade {
  background: #FFF;
  color: #111;
}

@media (prefers-color-scheme: dark) {
  .footer-shade {
    background: #161616;
    color: #FFF;
  }
}

/*School Logo and Name*/

.home-row {
  border-bottom: 2px solid #000;
  padding-bottom: 16px;
}
.preview-text {
  color: var(--ion-color-dark);
  font-size: 22px!important;
}
.logo-text {
  color: var(--ion-color-dark);
  font-size: 20px!important;
}
.logo-address {
  color: #111;
  font-size: 14px!important;
}

/* Buttons Styling with clear fill*/

.first-button {
  border: 2px solid var(--ion-color-secondary);
  color: var(--ion-color-secondary);
  font-weight: 600;
  text-transform: uppercase;
}
.second-button {
  border: 2px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  font-weight: 600;
  text-transform: uppercase;
}
.third-button {
  font-weight: 600;
  text-transform: uppercase;
}
.forth-button {
  border: 2px solid var(--ion-color-success);
  font-weight: 600;
  text-transform: uppercase;
}

/* Card Text*/
.first-meta {
  color: #111;
  font-size: 14px;
}
.second-meta {
color: #111;
font-size: 14px;
font-weight: 600;
}

/* Ion Icon Sizes*/
.first-icon {
  font-size: 64px;
}
.second-icon {
  font-size: 42px;
}
.third-icon {
  font-size: 30px;
  color: #111;
}
.forth-icon {
  font-size: 18px;
  color: #111;
}

/* pull to refresh*/
.pull-down-parent {
  height: 28px;
  background: var(--ion-color-secondary);
  padding-top: 6px;
  font-size: 14px;
}
.pull-text-parent {
  padding-left: 34px;
  padding-right: 34px;
  color: #FFF;
  font-weight: 600;
}
.pull-down {
  height: 28px;
  background: var(--ion-color-primary);
  padding-top: 6px;
  font-size: 14px;
}
.pull-text {
  padding-left: 34px;
  padding-right: 34px;
  color: var(--ion-color-secondary);
  font-weight: 600;
}

/* Alternate Items BG color */
.alt-item {
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
}

.alt-item-2 {
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
}

.alt-item-3 {
  --background: rgba(var(--ion-color-success-rgb), 0.2);
}

.alt-item-4 {
  --background: rgba(var(--ion-color-danger-rgb), 0.2);
}

/*parent cards*/

.danger-card {
  border-left: 6px solid var(--ion-color-danger);
  border-radius: 10px;
}

.success-card {
  border-left: 6px solid var(--ion-color-success);
  border-radius: 10px;
}

/*admin home buttons*/

.red-card {
  border-radius: 10px;
  border: 2px solid var(--ion-color-danger);
  --background: #fdfdfd;
}
.red-card .list-title h3 {
  font-size: 12px;
  font-weight: 600;
  color: var(--ion-color-danger);
  line-height: 18px;
}

.action-card {
  border-radius: 10px;
  border: 2px solid var(--ion-color-secondary);
  --background: #fdfdfd;
}
.action-card .action-title{
  color: var(--ion-color-secondary);
  text-transform: uppercase;
}
.action-card img {
  width: 30px;
  height: 30px;
}

@media (prefers-color-scheme: dark) {
  .action-card {
    --background: #191919;
  }

  .action-card .action-title{
    color: #fff;
  }
}

/*admin pages action cards*/
.action-card-b {
  border-radius: 10px;
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  border: 2px solid var(--ion-color-secondary);
}

.action-card-b img {
  width: 30px;
  height: 30px;
}

.action-card-c {
  border-radius: 10px;
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  border: 2px solid var(--ion-color-primary);
}

.action-card-d {
  border-radius: 10px;
  --background: rgba(var(--ion-color-danger-rgb), 0.2);
  border: 2px solid var(--ion-color-danger);
}

.action-card-s {
  border-radius: 10px;
  --background: rgba(var(--ion-color-success-rgb), 0.2);
  border: 2px solid var(--ion-color-success);
}

.action-link {
  background-color: var(--ion-color-secondary);
}

.card-link {
  color: var(--ion-color-secondary);
}

.action-text {
  color: var(--ion-color-dark);
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
}
.action-text-2 {
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.action-text-3 {
  color: var(--ion-color-dark);
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}
.action-text-4 {
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: 600;

}

/* Information on Admin Actions */
.info-text {
  font-size: 14px;
  color: var(--ion-color-medium);
}

/* Planner Admin */
.eventDay {
  border: 2px solid var(--ion-color-secondary) !important;
}

/*User Profile*/

.profile-card {
  border-radius: 10px;
  border: 2px solid var(--ion-color-secondary);
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
}

.profile-name {
  color: var(--ion-color-secondary);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.profile-email {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}


/* SwapCards*/
.parent-home-title{
  font-size: 16px;
  text-transform: capitalize;
  padding-top: 2px;
  color: #FFF;
}
.action-text-5 {
  color: #111;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.action-icon {
  font-size: 36px;
}

/* Admin Items*/
.item-transparent {
  --background: transparent;
}

.sub-label {
  color: var(--ion-color-secondary);
  font-weight: 600;
  font-size: 16px;
}

/* Tran Cards */
.tran-icon {
  font-size: 22px;
}
.tran-icon-2 {
  font-size: 18px;
}
.tran-rem {
  color: var(--ion-color-dark);
  font-size: 13px;
  line-height: 22px;
}

.tran-tag {
  display: inline-block;
  margin-left: 5px;
  margin-top: 10px;
  color: var(--ion-color-dark);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  border-radius: 2px;
  border: 2px solid var(--ion-color-secondary);
  padding: 5px;
}

.tran-text {
  color: var(--ion-color-dark);
  font-size: 13px;
  line-height: 22px;
}
.tran-pad {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 8px;
}
.tran-mode {
  color: var(--ion-color-secondary);
  font-size: 11px;
  line-height: 14px;
}
.tran-label {
  color: var(--ion-color-secondary);
  font-size: 11px;
  line-height: 14px;
}

.tran-row {
  background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.class-row {
  background: rgba(var(--ion-color-primary-rgb), 0.2);
}
.table-row {
  background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.title-row {
  background: var(--ion-color-secondary);
  color: #FFF;
}

/* Receipt */

.rec-logo {
  border-top: 2px solid #bcbcbc;
}
.rec-title {
  color: #111;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
.rec-text {
  color: #111;
  font-size: 16px;
  line-height: 22px;
}
.rec-label {
  color: var(--ion-color-secondary);
  font-size: 12px;
  line-height: 14px;
}
.rec-label-1 {
  color: #111;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}
.rec-row {
  padding-bottom: 40px;
  border-bottom: 2px solid #bcbcbc;
}
.rec-foot {
  color: #111;
  font-size: 12px;
  line-height: 16px;
}
.rec-paid {
  color: #111;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

/* Financial Reports */
.reports-in h1 {
  color: var(--ion-color-success);
  font-weight: 600;
  font-size: 24px;
}
.reports-out h1 {
  color: var(--ion-color-danger);
  font-weight: 600;
  font-size: 24px;
}
.reports-attend h1 {
  color: var(--ion-color-dark);
  font-size: 14px;
  line-height: 24px;
}
.attend-badge {
  font-size: 20px;
  right: 0;
  position:absolute;
}

/* preview pdf icon container*/
.pdf-preview {
  margin-top:30px;
  margin-bottom:30px;
}

/* Student Profile */
.profile-text {
  text-transform: capitalize;
  font-size: 18px;
}

/* Student Profile Reminder */
.overdue h1 {
  font-size: 32px;
  color: var(--ion-color-danger);
}


/* Parent Cards*/
.border-top {
  border-top: 1px solid #999;
}

.student-overdue h1 {
  font-size: 32px;
  color: #FFF;
  margin-left: 10px;
}


/* Notifications or Announcements List */
.note-date {
  font-size: 12px;
  color: #666;
}
.note-title {
  font-weight: 600;
  font-size: 14px;
  color: var(--ion-color-secondary);
}
.note-text {
  user-select: auto;
  font-size: 14px;
}

/* Parent Home In Out Status*/
.active-danger {
    border: 2px solid var(--ion-color-danger);
    border-radius: 10px;
}
.active-success {
  border: 2px solid var(--ion-color-success);
  border-radius: 10px;
}
.active-secondary {
  border: 2px solid var(--ion-color-secondary);
  border-radius: 10px;
}
.active-primary {
  border: 2px solid var(--ion-color-primary);
  border-radius: 10px;
}

.recent-label {
  font-weight: 600;
  color: #333;
  font-size: 11px;
}
.recent-label h3 {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}




/* Planner */
.planner-segment {
  --background: #FFF;
  font-weight: 600;
}

.planner-button {
  --background: var(--ion-color-light);
  --color: var(--ion-color-dark);
  --indicator-color: var(--ion-color-primary);
  --background-checked: var(--ion-color-primary);
  --color-checked: #000;
}


.planner-timeline h1 {
  position: absolute;
  top: 10%;
  right: 10%;
  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 600;
  
}
.planner-timeline h2 {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 600;
}

.planner-card {
  border-left: 6px solid var(--ion-color-secondary);
  border-radius: 10px;
}

.planner-card-2 {
  border-left: 6px solid var(--ion-color-primary);
  border-radius: 10px;
}

.planner-col h3 {
  font-size: 10px;
  color: var(--ion-color-medium);
  margin-top: 16px;
}

.planner-col p {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-dark);

}

/*Transaction*/

.transact-label h3 {
  font-size: 16px;
  color: var(--ion-color-dark);
  margin-top: 16px;
}
.transact-label p {
  font-size: 11px;
  color: var(--ion-color-medium);
}

.transact-amt h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 16px;
}
.transact-amt p {
  font-size: 11px;
}

/*Circulars*/

.circular-timeline {

  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 600;
  
}

.full-width {
  width: 260px !important;
}
.circular-card {
  border-left: 6px solid var(--ion-color-primary);
  border-radius: 10px;
}

.announce-card {
  border-left: 6px solid var(--ion-color-secondary);
  border-radius: 10px;
}

.circular-badge {
  margin-top: 16px;
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  --padding-bottom: 6px;
  --padding-end: 12px;
  --padding-start: 12px;
  --padding-top: 6px;
}

.announce-badge {
  margin-top: 16px;
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  --padding-bottom: 6px;
  --padding-end: 12px;
  --padding-start: 12px;
  --padding-top: 6px;
}

.circular-icon {
  font-size: 24px;
}

.announcement-card {
  min-height: 420px;
  user-select: auto !important;
}



@media (prefers-color-scheme: dark) {
  .white-shade {
    background: #000 !important; 
    color: #FFF !important;
  }

  .item-white-shade {
    --background: #000 !important; 
    color: #FFF !important;
  }
}

.circular-badge, .announce-badge, .first-meta, .second-meta {
  color: var(--ion-color-dark) !important;
}

.red-card {
  --background: #FFF !important;
}

@media (prefers-color-scheme: dark) {
  .red-card {
    --background: #000 !important;
  }
}

.home-row {
  border-bottom: 2px solid var(--ion-color-dark) !important;
}

@supports (-webkit-touch-callout: none) {
  .home-row {
    padding-top: 16px;
  }
}

ion-grid.white-shade {
  background: #FFF !important; 
  color: #000 !important;
}

@media (prefers-color-scheme: dark) {
  .ion-margin-vertical ion-grid.white-shade {
    background: #000 !important; 
    color: #FFF !important;
  }
}

.rec-logo .logo-text {
  color: #000 !important;
}